// Components
import Tile from 'components/Tile'


// Assets
import './styles.css'


import Config from 'other/Config';


const Tiles = () => {

    const ITEMS = [
        {
            name: 'Ordering',
            tileClass: 'Tile--ordering',
            description: 'Place those all important Pamper orders, view your sales and commission and manage your Ambassador account.',
            icon: 'icon-ordering.svg',
            url: Config.ORDERING_URL
        },
        {
            name: 'AMI Portal',
            tileClass: 'Tile--ami',
            description: 'Manage your customers and message templates via the Tropic AMI App desktop portal',
            icon: 'icon-ami.svg',
            url: Config.AMI_URL
        },
        {
            name: 'Stock & Delivery',
            tileClass: 'Tile--stock',
            description: 'Keep up to date with the latest stock and delivery updates for all your favourite Tropic products.',
            icon: 'icon-delivery.svg',
            url: Config.STOCKUPDATES_URL
        },
        {
            name: 'Tropic Market',
            tileClass: 'Tile--market',
            description: 'Browse Tropic-branded Pamper essentials, stationery, clothing, homeware and more!',
            icon: 'icon-market.svg',
            url: Config.MARKET_URL
        },
        {
            name: 'The Lounge',
            tileClass: 'Tile--lounge',
            description: 'Soak up an adundance of Tropic knowledge with your Lounge learning programs, plus stay up to date with the latest from HQ',
            icon: 'icon-lounge.svg',
            url: Config.LOUNGE_URL
        },
        {
            name: 'Tropic Social',
            tileClass: 'Tile--social',
            description: 'Discover thousands of branded marketing assets and schedule content across your social platforms',
            icon: 'icon-social.svg',
            url: Config.SOCIAL_URL
        }
    ]

    return (
        <div className="Tiles Container">
            {ITEMS.map((item) => {
                return <Tile key={item.name} {...item}/>
            })}
        </div>
    )
}

export default Tiles