/*

Main configuration file for this app

React only allows two environments natively (dev and prod), so
we have to manage our own env config :(

*/


/*
General Configuration
*/
const generalConfig = {
	STAGE: process.env.REACT_APP_STAGE,
	AUTH0_SCOPES: "openid profile email"
}


/*
Environment-specific Configuration
*/
const environmentConfig = {

	/*
	Production Environment Configuration
	*/
	production: {
		API_URL: "https://api.ambassador.tropicskincare.com",
		AUTH0_DOMAIN: "id.tropicskincare.com",
		AUTH0_CLIENT_ID: "cYs1fAhuaDZfCQ5LRKTg0J0a0UWqhQyQ",

		APPSTATUS_URL: "https://appstatus.admin.tropicskincare.com/ambassador-access.json",

		ORDERING_URL: "https://www.tropicambassadors.com",
		LOUNGE_URL: "https://lounge.tropicskincare.com",
		SOCIAL_URL: "https://multibrain.me/sso/tropic/auth0",
		MARKET_URL: "https://market.tropicskincare.com",
		AMI_URL: "https://my.ami.co/auth/company/tropic-skincare",
		STOCKUPDATES_URL: "/stock"
	},
	

	/*
	Staging Environment Configuration
	*/
	staging: {
		API_URL: "https://api.ambassador.stage.tropicskincare.com",

		AUTH0_DOMAIN: "id.stage.tropicskincare.com",
		AUTH0_CLIENT_ID: "HqdikqKpzoG7shNf2pBdJIycSiANqDCc",

		APPSTATUS_URL: "https://appstatus.admin.stage.tropicskincare.com/ambassador-access.json",

		ORDERING_URL: "https://www.tropicambassadors.com/test",
		LOUNGE_URL: "http://tropicskincare-sandbox.rallyware.com/",
		SOCIAL_URL: "https://staging.multibrain.me/sso/tropic/auth0",
		MARKET_URL: "https://market.tropicskincare.com",
		AMI_URL: "https://my.ami.co/auth/company/tropic-skincare-sandbox",
		STOCKUPDATES_URL: "/stock"
	},


	/*
	Development Environment Configuration
	*/
	development: {
		API_URL: "https://api.ambassador.dev.tropicskincare.com",

		AUTH0_DOMAIN: "id.dev.tropicskincare.com",
		AUTH0_CLIENT_ID: "5vUE2WYHJTAUFAWVs8MF62ssZSAwJshL",

		APPSTATUS_URL: "https://appstatus.admin.dev.tropicskincare.com/ambassador-access.json",

		ORDERING_URL: "https://www.tropicambassadors.com/test",
		LOUNGE_URL: "http://tropicskincare-sandbox.rallyware.com/",
		SOCIAL_URL: "https://staging.multibrain.me/sso/tropic/auth0",
		MARKET_URL: "https://market.tropicskincare.com",
		AMI_URL: "https://my.ami.co/auth/company/tropic-skincare-sandbox",
		STOCKUPDATES_URL: "/stock"
	}

}

const config = {
	...generalConfig,
	...environmentConfig[process.env.REACT_APP_STAGE]
}

export default config;
