// Packages
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';


// Assets
import './styles.css';


const Tile = props => {

	const { name, 
			tileClass, 
			description, 
			url, 
			icon } = props;

	const imageSrc = require(`assets/${icon}`).default;

	const navigate = useNavigate();

	return (
		<a href={url} onClick={(e) => {

			e.preventDefault();
			
			if ( url.substr(0, 4) === 'http' )
			{
				// External link
				window.open(url, '_blank');
				//window.location = url;
			}
			else
			{
				// Internal link
				navigate(url);
			}	

		}} className={`Tile ${tileClass}`}>

		<div className="Tile__body">

			<div className="Tile__icon">
				<img src={imageSrc} alt={name} className="Tile__icon_image"/>
			</div>

			<div className="Tile__name">
				{name}
			</div>

			<div className="Tile__description">
				{description}
			</div>

		</div>
		
		</a>
	)

}

Tile.propTypes = {
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	tileClass: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired
}

export default Tile;